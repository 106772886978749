import React from 'react'

const VisionSection = () => {
  return (
    <div className='vision_section pt-[50px] '>
        <img src="/image/about/vision-img.png" className='w-full h-auto' alt="" />
    </div>
  )
}

export default VisionSection